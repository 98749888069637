/* Bruteforce hide "ResizeObserver loop completed with undelivered notifications" overlay which come from MUI Input Field with multiline property */
iframe#webpack-dev-server-client-overlay {
    display: none !important
}

/* hide autofill safari icon in input fields */
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
}

/* change the calendar icon colour of the TextField component for type="date" */
::-webkit-calendar-picker-indicator {
    filter: invert(1)
    brightness(50%)
}

#root {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    font-family: "Roboto", sans-serif;
    margin: 0;
}

.header .footer {
    flex: 0 1 auto;
}

.content {
    display: flex;
    flex: 1 1 auto;
}

/* Header Menu */
.header-menu-item {
    height: 44px;

    .icon {
        margin-right: 14px;
    }
}

/* Small screen and tablet (600px to 1023px) */
@media (max-width: 1023px) {
    .wrapper {
        padding-right: 16px;
        padding-left: 16px;
    }
}

/* Desktop (1024px to 1680px) */
@media (min-width: 1024px) and (max-width: 1679px) {
    .wrapper {
        padding-right: 24px;
        padding-left: 24px;
    }
}

/* Big desktop (1681px and up) */
@media (min-width: 1680px) {
    .wrapper {
        padding-right: 32px;
        padding-left: 32px;
    }
}
